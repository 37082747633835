import React from 'react';
import PropTypes from 'prop-types';
import { connectToStores } from 'fluxible-addons-react';

function Header(props) {
    const { logo } = props;

    return (
        <header className="unauthenticated-header">
            <span
                className="logo"
                role="button"
                tabIndex={0}
                aria-label={`${logo.alt} logo, click to go home`}
            >
                <img type="image" alt={logo.alt} src={logo.url} />
            </span>
        </header>
    );
}

Header.propTypes = {
    logo: PropTypes.string.isRequired,
};

Header.defaultProps = {};

export default connectToStores(Header, ['ApplicationContext'], context => {
    const applicationContextStore = context.getStore('ApplicationContext');

    return {
        logo: applicationContextStore.l10n('client.images.brand'),
    };
});
