import requirementIds from '../requirement-ids';
import CreatePassword from './create-password';
import Eula from './eula';
import ResetPassword from './reset-password';
import SetSecurityQuestions from './set-security-questions';

const requirementPages = {
    [requirementIds.createPassword]: CreatePassword,
    [requirementIds.eula]: Eula,
    [requirementIds.resetPassword]: ResetPassword,
    [requirementIds.setSecurityQuestions]: SetSecurityQuestions,
};

export default requirementPages;
