import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Header from './header';
import Footer from './footer';
import './unauthenticated-page.scss';

class UnauthenticatedPage extends PureComponent {
    render() {
        const { children } = this.props;

        return (
            <div className="unauthenticated-page">
                <Header />
                <main className="main-content">{children}</main>
                <div className="unauthenticated-flex-buffer" />
                <Footer />
            </div>
        );
    }
}

UnauthenticatedPage.propTypes = {
    children: PropTypes.element,
};

UnauthenticatedPage.defaultProps = {
    children: null,
};

export default UnauthenticatedPage;
