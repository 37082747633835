/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import applyFluxibleContext from '@audacious/web-common/fluxible/applyFluxibleContext';
import { connectToStores } from 'fluxible-addons-react';
import isEmpty from 'lodash/isEmpty';
import { Row, Column } from '@audacious/components/components/Grid';
import { SubHeading } from '@audacious/components/components/Typography';
import Link from '@audacious/components/components/Link';
import Button from '@audacious/components/components/Button';
import TextInput from '@audacious/components/components/TextInput';

class SSOForm extends PureComponent {
    constructor(props) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSwitchToUsernameLogin = this.handleSwitchToUsernameLogin.bind(
            this,
        );
        this.handleAccountChange = this.handleAccountChange.bind(this);

        this.state = {
            accountNumber: null,
        };
    }

    handleSubmit(event) {
        const {
            fluxibleContext: {
                service: {
                    startSSOWorkflow,
                },
            },
        } = this.props;

        const { redirect, tenantId } = this.props;

        event.preventDefault();

        const { accountNumber } = this.state;

        startSSOWorkflow({
            options: {
                accountNumber,
                tenantId,
            },
            params: {
                redirect,
            },
        });
    }

    handleSwitchToUsernameLogin() {
        const { onChangeMode } = this.props;

        onChangeMode('username');
    }

    handleAccountChange(accountNumber) {
        this.setState({
            accountNumber,
        });
    }

    render() {
        const { invalid, disabled } = this.props;

        const { accountNumber } = this.state;

        // const errorMessage = invalid ? (
        //     <p className="danger mb3 pa3 f6 w-100">
        //         Incorrect account number
        //     </p>
        // ) : null;

        const messages = invalid
            ? { invalid: ['Incorrect account number'] }
            : null;

        return (
            <form onSubmit={this.handleSubmit}>
                <Row gutter="16">
                    <Column width="content">
                        <SubHeading level="5">Login</SubHeading>
                    </Column>
                    <Column width="content" justify="right">
                        <Link
                            id="login-button-use-sso"
                            onClick={this.handleSwitchToUsernameLogin}
                            asButton
                            size="xs"
                        >
                            Sign in with username
                        </Link>
                    </Column>
                </Row>
                <Row gutter="16">
                    <Column>
                        <TextInput
                            id="sso-account"
                            value={accountNumber}
                            placeholder="Account"
                            aria-label="Account"
                            onChange={this.handleAccountChange}
                            messages={messages}
                            invalid={invalid}
                        />
                    </Column>
                </Row>
                <Row gutter="16">
                    <Column>
                        <Button
                            id="sso-button-submit"
                            type="submit"
                            disabled={isEmpty(accountNumber) || disabled}
                        >
                            Login
                        </Button>
                    </Column>
                </Row>
            </form>
        );
    }
}

SSOForm.contextTypes = {
    service: PropTypes.objectOf(
        PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    ).isRequired,
};

SSOForm.propTypes = {
    tenantId: PropTypes.string.isRequired,
    invalid: PropTypes.bool,
    disabled: PropTypes.bool,
    onChangeMode: PropTypes.func.isRequired,
    redirect: PropTypes.string,
    fluxibleContext: PropTypes.shape({
		service: PropTypes.shape({
            startSSOWorkflow: PropTypes.func.isRequired,
		}),
	}).isRequired,
};

SSOForm.defaultProps = {
    disabled: false,
    invalid: false,
    redirect: null,
};

export default connectToStores(applyFluxibleContext(SSOForm), ['Session'], context => {
    const sessionStore = context.getStore('Session');
    const appContextStore = context.getStore('ApplicationContext');

    return {
        tenantId: appContextStore.getTenantId(),
        redirect: sessionStore.getRedirect(),
    };
});
