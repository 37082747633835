import React from 'react';
import PropTypes from 'prop-types';
import { connectToStores } from 'fluxible-addons-react';
import isNil from 'lodash/isNil';
import forEach from 'lodash/forEach';
import { AuthenticatedPage } from '@audacious/client';
import {
    PageContainer,
    PageTitle,
} from '@audacious/components/components/Page';
import PulseQuickLinks from './pulse-quick-links';
import PromptQuickLinks from './prompt-quick-links';
import EcQuickLinks from './ec-quick-links';
import MissingPersonsQuickLinks from './missing-persons-quick-links';

import './home.scss';

const quickLinksComponents = [
    { id: 'pulse', Component: PulseQuickLinks },
    { id: 'ec', Component: EcQuickLinks },
    { id: 'prompt', Component: PromptQuickLinks },
    { id: 'missing-persons', Component: MissingPersonsQuickLinks },
];

function HomePage(props) {
    const { entitlements, subscriptions } = props;
    const quickLinks = [];

    forEach(quickLinksComponents, ({ id, Component }) => {
        if (!entitlements.hasEntitlement(id)) {
            return;
        }

        const subscription = subscriptions.getById(id);

        if (isNil(subscription)) {
            return;
        }

        const { name, url } = subscription;

        quickLinks.push(<Component key={id} name={name} url={url} />);
    });

    let quickLinksCard = null;

    if (quickLinks.length > 0) {
        quickLinksCard = <PageContainer asCard>{quickLinks}</PageContainer>;
    }

    return (
        <AuthenticatedPage>
            <PageTitle id="home-page-title" pageName="Dashboard" />
            {quickLinksCard}
        </AuthenticatedPage>
    );
}

HomePage.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    entitlements: PropTypes.object.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    subscriptions: PropTypes.object.isRequired,
};

export default connectToStores(
    HomePage,
    ['Session', 'ApplicationContext'],
    context => {
        const sessionStore = context.getStore('Session');
        const applicationContext = context.getStore('ApplicationContext');

        return {
            entitlements: sessionStore.getEntitlements(),
            subscriptions: applicationContext.getTenantSubscriptions(),
        };
    },
);
