import isNil from 'lodash/isNil';

function onSuccess(content, callContext) {
    const redirect = callContext?.response?.data?.redirect;

    if (isNil(redirect)) {
        throw new Error('Redirect not provided.');
    }

    window.location = redirect;
}

function onFailed(context, { error }) {
    context.dispatch('SET_SESSION_FAILURE', error);
}

export default () => ({
    serviceName: 'startSSOWorkflow',
    config: {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: '/common/v1/sso/{tenantId}/{accountNumber}/startSSOWorkflow',
        maxRedirects: 0,
    },
    onSuccess,
    onFailed,
    onError: onFailed,
    onTimeout: onFailed,
    onFatal: onFailed,
});
