import get from 'lodash/get';

import { createMockServiceConfiguration } from '../../services';

export default config => {
    const contextConfiguration = config;
    contextConfiguration.services = createMockServiceConfiguration(
        get(config, 'services', {}),
    );
    return contextConfiguration;
};
