import React, { memo } from 'react';
import Card from '@audacious/components/components/Card';
import {
    SubHeading,
    Paragraph,
} from '@audacious/components/components/Typography';
import UnauthenticatedPage from '../common/unauthenticated-page';

function LogoutPage() {
    return (
        <UnauthenticatedPage>
            <div className="login-content">
                <Card>
                    <SubHeading className="login-sub-heading" level="5">
                        Logout
                    </SubHeading>
                    <Paragraph size="md">
                        You have been successfully logged out of the platform.
                    </Paragraph>
                </Card>
            </div>
        </UnauthenticatedPage>
    );
}

LogoutPage.propTypes = {};

LogoutPage.defaultTypes = {};

export default memo(LogoutPage);
