import React from 'react';
import PropTypes from 'prop-types';
import { SubHeading } from '@audacious/components/components/Typography';
import Button from '@audacious/components/components/Button';
import map from 'lodash/map';

function QuickLinks(props) {
    const { title, links } = props;

    return (
        <>
            <SubHeading
                id="checkInHeading"
                className="home-link-heading"
                level="4"
            >
                {title}
            </SubHeading>
            <div className="ai-button-group home-link-group">
                {map(links, link => (
                    <Button
                        key={link.id}
                        id={link.id}
                        className="home-button"
                        color="primary"
                        variant="outline"
                        href={link.url}
                        asAnchor
                    >
                        <span>{link.text}</span>
                    </Button>
                ))}
            </div>
        </>
    );
}

QuickLinks.propTypes = {
    title: PropTypes.string.isRequired,
    links: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired,
            text: PropTypes.string.isRequired,
        }),
    ).isRequired,
};

export default QuickLinks;
