import CommonStore from '@audacious/web-common/fluxible/CommonStore';

const INITIAL_STATE = {
    mode: 'username',
};

class LoginStore extends CommonStore {
    constructor(dispatcher) {
        super(dispatcher, INITIAL_STATE);
    }

    setMode(mode) {
        this.setState({
            mode,
        });
    }

    getMode() {
        return this.state.mode;
    }
}

LoginStore.storeName = 'LoginStore';
LoginStore.handlers = {
    SET_LOGIN_MODE: 'setMode',
};

export default LoginStore;
