import './styles.scss';
import Pendo from '@audacious/fluxible-pendo';
import get from 'lodash/get';
import { Client } from '@audacious/client';
import stores from './stores';
import { createServiceConfiguration } from './services';
import { createApplicationContext, createContextConfiguration } from './config';
import Application from './components/application';
import Login from './components/login';
import Logout from './components/logout';
import Requirements from './components/requirements';

export default config => {
    const app = new Client({
        components: {
            Application,
            Login,
            Logout,
            Requirements,
        },
        stores,
        context: createApplicationContext(get(config, 'context', {})),
        services: createServiceConfiguration(get(config, 'services', {})),
    });

    app.plug(Pendo);

    function createContext(contextConfiguration) {
        const context = app.createContext(
            createContextConfiguration(contextConfiguration),
        );

        return context;
    }

    return {
        createContext,
        getComponent: app.getComponent.bind(app),
    };
};
