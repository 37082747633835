function beforeRequest(context, serviceContext) {
    const applicationContextStore = context.getStore('ApplicationContext');

    serviceContext.setOptions({
        tenantId: applicationContextStore.getTenantId(),
    });
}

function onSuccess(context, { response }) {
    context.dispatch('SET_AVAILABLE_AGREEMENTS', [null, response.data]);
}

function onFailed(context, { error }) {
    context.dispatch('SET_AVAILABLE_AGREEMENTS', [error, []]);
}

export default () => ({
    serviceName: 'retrieveAvailableAgreements',
    config: {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: '/common/v1/tenants/{tenantId}/settings/agreements',
    },
    beforeRequest,
    onSuccess,
    onFailed,
    onError: onFailed,
    onTimeout: onFailed,
    onFatal: onFailed,
});
