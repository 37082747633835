export default {
    content: {
        welcome: {
            announcement:
                'Welcome to the Ai Web Client Framework! \n\n This web client contains the all the components of our platform which are shared among all of our applications. \n\n This includes but is not limited to common features such as authentication and authorization, federated login and logout, session management, application configuration and application localization.',
        },
        support:
            'Please contact your PULSE administrator with support questions.',
    },
};
