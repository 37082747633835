import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connectToStores } from 'fluxible-addons-react';
import RichTextViewer from '@audacious/components/components/RichTextViewer';
import { SubHeading, Paragraph } from '@audacious/components/components/Typography';
import Card from '@audacious/components/components/Card';
import { Row, Column } from '@audacious/components/components/Grid';
import cn from 'classnames';
import Login from './login';

class LoginContent extends PureComponent {
    render() {
        const {
            l10n,
            loading,
            invalidLogin,
            invalidForgotPassword,
            passwordMessage,
        } = this.props;

        return (
            <div className="login-content">
                {typeof passwordMessage === 'string' &&
                passwordMessage.length > 0 ? (
                    <Paragraph
                        className="reset-password-success eight-seconds-hide"
                        color="success"
                        size="md"
                    >
                        {passwordMessage}
                    </Paragraph>
                ) : null}
                <Row gutter="16">
                    <Column width={[null, null, 'fill', '4']}>
                        <Card className="login-card">
                            <SubHeading
                                className="login-sub-heading"
                                level="5"
                                color="primary"
                            >
                                Welcome
                            </SubHeading>
                            <RichTextViewer
                                id="welcome-rtv"
                                content={l10n(
                                    'client.text.login.content.welcome.announcement',
                                )}
                            />
                        </Card>
                    </Column>
                    <Column width={[null, null, '5', '4']}>
                        <Card
                            className={cn('login-card', {
                                'is-loading': loading,
                            })}
                        >
                            <Login
                                disabledLogin={loading}
                                disabledForgotPassword={loading}
                                invalidLogin={invalidLogin}
                                invalidForgotPassword={invalidForgotPassword}
                            />
                        </Card>
                    </Column>
                    <Column width={[null, null, 'fill', '4']}>
                        <Card className="login-card">
                            <SubHeading className="login-sub-heading" level="5">
                                Support
                            </SubHeading>
                            <RichTextViewer
                                id="support-rtv"
                                content={l10n(
                                    'client.text.login.content.support',
                                )}
                            />
                        </Card>
                    </Column>
                </Row>
            </div>
        );
    }
}

LoginContent.propTypes = {
    l10n: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    invalidLogin: PropTypes.bool,
    invalidForgotPassword: PropTypes.bool,
    passwordMessage: PropTypes.string,
};

LoginContent.defaultProps = {
    loading: false,
    invalidLogin: false,
    invalidForgotPassword: false,
    passwordMessage: null,
};

export default connectToStores(
    LoginContent,
    ['ApplicationContext'],
    context => ({
        l10n: context.getStore('ApplicationContext').l10n,
    }),
);
