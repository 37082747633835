import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { DataAccess } from '@audacious/components/components/Data';
import Response from './response';

function DataResponse(props) {
    const {
        usedQuestions,
        onQuestionChange,
        availableQuestions
    } = props;

    return (
        <DataAccess>
            {(context) => (
                <Response 
                    value={context.property.value}
                    availableQuestions={availableQuestions}
                    usedQuestions={usedQuestions}
                    onQuestionChange={onQuestionChange}
                />
            )}
        </DataAccess>
    );
}

DataResponse.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    availableQuestions: PropTypes.object.isRequired,
    usedQuestions: PropTypes.objectOf(PropTypes.bool).isRequired,
    onQuestionChange: PropTypes.func.isRequired,
};

export default memo(DataResponse);
