import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import applyFluxibleContext from '@audacious/web-common/fluxible/applyFluxibleContext';
import PasswordForm from '../common/password-form';

class CreatePassword extends PureComponent {
    constructor(props) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(password) {
        const {
            fluxibleContext: {
                service: {
                    resolveCreatePassword
                },
            },
         } = this.props;

        resolveCreatePassword({
            data: {
                password,
            },
        });
    }

    render() {
        return <PasswordForm onSubmit={this.handleSubmit} />;
    }
}

CreatePassword.propTypes = {
    fluxibleContext: PropTypes.shape({
		service: PropTypes.shape({
            resolveCreatePassword: PropTypes.func.isRequired,
		}),
	}).isRequired,
};

export default applyFluxibleContext(CreatePassword);
