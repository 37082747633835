function beforeRequest(context) {
    context.dispatch('SET_REQUIREMENTS_STATUS', [null, true]);
}

function onSuccess(context) {
    context.service.getSession();

    context.dispatch('RESET_REQUIREMENTS');
}

function onFailed(context, { error }) {
    context.dispatch('SET_REQUIREMENTS_STATUS', [error, false]);
}

export default () => ({
    serviceName: 'resolveSetSecurityQuestions',
    config: {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: '/common/v2/requirements/set-security-questions',
    },
    beforeRequest,
    onSuccess,
    onFailed,
    onError: onFailed,
    onTimeout: onFailed,
    onFatal: onFailed,
});
