/* eslint-disable react/no-access-state-in-setstate */
import CommonStore from '@audacious/web-common/fluxible/CommonStore';
import { ObjectCollection } from '@audacious/client';
import { v4 as uuidv4 } from 'uuid';
import isNil from 'lodash/isNil';

const INITIAL_STATE = {
    status: {
        processing: false,
        failure: null,
    },
    availableAgreements: {
        isLoaded: false,
        agreements: null,
        failure: null,
    },
    availableQuestions: {
        isLoaded: false,
        questions: null,
        failure: null,
    },
    challenge: {
        isLoaded: false,
        questions: null,
        answered: false,
        question: null,
        tryCount: 0,
        failure: null,
    },
};

function initQuestionCollection(questions) {
    const collection = new ObjectCollection(
        questions,
        question => question.id,
        {
            onLoad: question => ({
                ...question,
                isCustom: question.isCustom || false,
                id: uuidv4(),
            }),
        },
    );

    collection.push({
        id: uuidv4(),
        question: 'Write my own question',
        isCustom: true,
    });

    return collection;
}

class RequirementsStore extends CommonStore {
    constructor(dispatcher) {
        super(dispatcher, INITIAL_STATE);
    }

    setAvailableAgreements([failure, agreements]) {
        this.setState({
            availableAgreements: {
                isLoaded: true,
                agreements,
                failure,
            },
        });
    }

    setAvailableQuestions([failure, questions]) {
        this.setState({
            availableQuestions: {
                isLoaded: true,
                questions: initQuestionCollection(questions),
                failure,
            },
        });
    }

    setSelectedQuestions([failure, questions]) {
        this.setState({
            challenge: {
                ...INITIAL_STATE.challenge,
                isLoaded: true,
                questions,
                failure,
                question: isNil(failure) ? questions[0] : null,
            },
        });
    }

    setRequirementsStatus([failure, isProcessing]) {
        this.setState({
            status: {
                processing: isProcessing,
                failure,
            },
        });
    }

    recordChallengeAttempt([failure]) {
        let { tryCount } = this.state.challenge;

        tryCount += 1;

        this.setState({
            challenge: {
                ...this.state.challenge,
                answered: isNil(failure),
                tryCount,
                question: this.state.challenge.questions[tryCount],
            },
        });
    }

    getAvailableAgreements() {
        return this.state.availableAgreements;
    }

    getAvailableQuestions() {
        return this.state.availableQuestions;
    }

    getRequirementsStatus() {
        return this.state.status;
    }

    getChallenge() {
        return this.state.challenge;
    }
}

RequirementsStore.storeName = 'Requirements';
RequirementsStore.handlers = {
    SET_AVAILABLE_AGREEMENTS: 'setAvailableAgreements',
    SET_AVAILABLE_QUESTIONS: 'setAvailableQuestions',
    SET_SELECTED_QUESTIONS: 'setSelectedQuestions',
    SET_REQUIREMENTS_STATUS: 'setRequirementsStatus',
    RECORD_CHALLENGE_ATTEMPT: 'recordChallengeAttempt',
    RESET_REQUIREMENTS: 'resetState',
};

export default RequirementsStore;
