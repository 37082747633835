import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import applyFluxibleContext from '@audacious/web-common/fluxible/applyFluxibleContext';
import { connectToStores } from 'fluxible-addons-react';
import isNil from 'lodash/isNil';
import Button from '@audacious/components/components/Button';
import RichTextViewer from '@audacious/components/components/RichTextViewer';
import {
    PageTitle,
    PageContainer,
} from '@audacious/components/components/Page';
import { Text } from '@audacious/components/components/Typography';
import { Row, Column } from '@audacious/components/components/Grid';

import './eula.scss';

class Eula extends PureComponent {
    constructor(...args) {
        super(...args);

        this.handleAgreeClick = this.handleAgreeClick.bind(this);
        this.handleDisagreeClick = this.handleDisagreeClick.bind(this);
    }

    componentDidMount() {
        const {
            fluxibleContext: {
                service: {
                    retrieveAvailableAgreements
                },
            },
         } = this.props;

        retrieveAvailableAgreements();
    }

    handleAgreeClick() {
        const {
            agreementItem: { id },
            fluxibleContext: {
                service: {
                    resolveEula,
                },
            },
        } = this.props;

        resolveEula({
            data: [id],
        });
    }

    handleDisagreeClick() {
        const {
            fluxibleContext: {
                service: {
                    destroySession
                },
            },
         } = this.props;

        destroySession();
    }

    render() {
        const {
            isLoaded,
            agreementItem: { agreement },
        } = this.props;

        let content = null;

        if (isLoaded) {
            if (isNil(agreement)) {
                content = (
                    <Text weight="semi-bold" size="lg" color="danger">
                        No agreement found. Please contact your administrator.
                    </Text>
                );
            } else {
                content = (
                    <>
                        <Row gutter="16">
                            <Column>
                                <RichTextViewer id="eula" content={agreement} />
                            </Column>
                        </Row>
                        <Row gutter="16">
                            <Column width="content">
                                <Button
                                    id="notice-agree-btn"
                                    variant="fill"
                                    color="primary"
                                    onClick={this.handleAgreeClick}
                                >
                                    I Agree
                                </Button>
                            </Column>
                            <Column width="content">
                                <Button
                                    id="notice-disagree-btn"
                                    variant="opaque"
                                    color="primary"
                                    onClick={this.handleDisagreeClick}
                                >
                                    I Disagree
                                </Button>
                            </Column>
                        </Row>
                    </>
                );
            }
        }

        return (
            <>
                <PageTitle
                    id="set-password-page-title"
                    pageName="Terms & Agreements"
                />
                <PageContainer
                    className="eula-page"
                    asCard={isLoaded}
                    isLoading={!isLoaded}
                >
                    {content}
                </PageContainer>
            </>
        );
    }
}

Eula.propTypes = {
    isLoaded: PropTypes.bool.isRequired,
    agreementItem: PropTypes.shape({
        agreement: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
    }).isRequired,
    fluxibleContext: PropTypes.shape({
		service: PropTypes.shape({
            retrieveAvailableAgreements: PropTypes.func.isRequired,
            resolveEula: PropTypes.func.isRequired,
            destroySession: PropTypes.func.isRequired,
		}),
	}).isRequired,
};

Eula.defaultProps = {};

export default connectToStores(applyFluxibleContext(Eula), ['Requirements'], context => {
    const requirementsStore = context.getStore('Requirements');

    const { isLoaded, agreements } = requirementsStore.getAvailableAgreements();

    return {
        isLoaded,
        agreementItem: !isNil(agreements) ? agreements[0] : {},
    };
});
