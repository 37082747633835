import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import applyFluxibleContext from '@audacious/web-common/fluxible/applyFluxibleContext';
import { connectToStores } from 'fluxible-addons-react';
import Button from '@audacious/components/components/Button';
import TextInput from '@audacious/components/components/TextInput';
import isNil from 'lodash/isNil';
import get from 'lodash/get';
import {
    PageContainer,
    PageTitle,
} from '@audacious/components/components/Page';
import { Paragraph } from '@audacious/components/components/Typography';
import { Column, Row } from '@audacious/components/components/Grid';

const MAX_TRIES = 2;

class QuestionChallenge extends PureComponent {
    constructor(props) {
        super(props);

        this.handleSubmitAnswer = this.handleSubmitAnswer.bind(this);
        this.handleAnswerChange = this.handleAnswerChange.bind(this);

        this.state = {
            answer: null,
            previousTryCount: null,
        };
    }

    static getDerivedStateFromProps(nextProps, currentState) {
        const {
            challenge: { tryCount },
        } = nextProps;

        const { previousTryCount } = currentState;

        if (tryCount <= previousTryCount) {
            return null;
        }

        // Clear the answer if the try count changes.
        return {
            previousTryCount: tryCount,
            answer: null,
        };
    }

    componentDidMount() {
        const {
            fluxibleContext: {
                service: {
                    retrieveSelectedQuestions
                },
            },
         } = this.props;

        retrieveSelectedQuestions();
    }

    handleSubmitAnswer() {
        const {
            challenge: { question },
            fluxibleContext: {
                service: {
                    answerSecurityQuestion
                },
            },
        } = this.props;

        const { answer } = this.state;

        const questionToSend = get(question, 'question');

        answerSecurityQuestion({
            data: { answer, question: questionToSend },
        });
    }

    handleAnswerChange(answer) {
        this.setState({
            answer,
        });
    }

    render() {
        const {
            children,
            challenge: { isLoaded, failure, answered, tryCount, question },
        } = this.props;

        const { answer } = this.state;

        if (answered) {
            return children;
        }

        let content = null;

        if (isLoaded) {
            if (!isNil(failure)) {
                content = (
                    <Paragraph weight="semi-bold" size="lg" color="danger">
                        Something went wrong. Please try again or contact your
                        administrator.
                    </Paragraph>
                );
            } else if (tryCount > MAX_TRIES) {
                content = (
                    <Paragraph weight="semi-bold" size="lg" color="danger">
                        Max attempts reached to enter your security question.
                        Please contact your administrator.
                    </Paragraph>
                );
            } else {
                const messages =
                    tryCount > 0
                        ? {
                              invalid: [
                                  'The answer you have entered is incorrect. Please try again.',
                              ],
                          }
                        : null;

                content = (
                    <Row gutter="16">
                        <Column>
                            <Paragraph
                                className="security-question-heading"
                                size="md"
                            >
                                Please answer the following Security Question:
                            </Paragraph>
                        </Column>
                        <Column>
                            <Paragraph
                                className="security-question"
                                weight="bold"
                                size="md"
                            >
                                {question.question}
                            </Paragraph>
                        </Column>
                        <Column>
                            <TextInput
                                id="question-challenge-answer"
                                placeholder="Answer"
                                value={answer}
                                onChange={this.handleAnswerChange}
                                messages={messages}
                            />
                        </Column>
                        <Column>
                            <Button
                                id="question-challenge-btn"
                                variant="fill"
                                color="primary"
                                disabled={!answer}
                                onClick={this.handleSubmitAnswer}
                            >
                                Next
                            </Button>
                        </Column>
                    </Row>
                );
            }
        }

        return (
            <>
                <PageTitle id="challenge-page-title" pageName="Verification" />
                <PageContainer
                    className="challenge-page"
                    asCard={isLoaded}
                    isLoading={!isLoaded}
                >
                    {content}
                </PageContainer>
            </>
        );
    }
}

QuestionChallenge.contextTypes = {
    service: PropTypes.objectOf(
        PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    ).isRequired,
};

QuestionChallenge.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node),
    ]),
    challenge: PropTypes.shape({
        isLoaded: PropTypes.bool.isRequired,
        // eslint-disable-next-line react/forbid-prop-types
        failure: PropTypes.object,
        answered: PropTypes.bool.isRequired,
        tryCount: PropTypes.number.isRequired,
        // eslint-disable-next-line react/forbid-prop-types
        question: PropTypes.object,
    }).isRequired,
    fluxibleContext: PropTypes.shape({
		service: PropTypes.shape({
            retrieveSelectedQuestions: PropTypes.func.isRequired,
            answerSecurityQuestion: PropTypes.func.isRequired,
		}),
	}).isRequired,
};

QuestionChallenge.defaultProps = {
    children: null,
};

export default connectToStores(
    applyFluxibleContext(QuestionChallenge),
    ['Requirements', 'ApplicationContext'],
    context => {
        const requirementsStore = context.getStore('Requirements');

        const challenge = requirementsStore.getChallenge();

        return {
            l10n: context.getStore('ApplicationContext').l10n,
            challenge,
        };
    },
);
