import React from 'react';
import PropTypes from 'prop-types';
import urlJoin from 'url-join';
import QuickLinks from './quick-links';

function PromptQuickLinks(props) {
    const { name, url } = props;

    const links = [
        {
            id: 'prompt',
            text: 'Notifications',
            url: urlJoin(url, '/'),
        },
    ];

    return <QuickLinks title={name} links={links} />;
}

PromptQuickLinks.propTypes = {
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
};

export default PromptQuickLinks;
