import React from 'react';
import PropTypes from 'prop-types';
import urlJoin from 'url-join';
import QuickLinks from './quick-links';

function EcQuickLinks(props) {
    const { name, url } = props;

    const links = [
        {
            id: 'ec-home',
            text: 'Emergency Census',
            url: urlJoin(url, '/home'),
        },
    ];

    return <QuickLinks title={name} links={links} />;
}

EcQuickLinks.propTypes = {
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
};

export default EcQuickLinks;
