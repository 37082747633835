function onSuccess(context) {
    context.dispatch('RECORD_CHALLENGE_ATTEMPT', [null]);
}

function onFailed(context, serviceContext) {
    context.dispatch('RECORD_CHALLENGE_ATTEMPT', [serviceContext.error]);
}

export default () => ({
    serviceName: 'answerSecurityQuestion',
    config: {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: '/common/v2/securityQuestions/challenge',
    },
    onSuccess,
    onFailed,
    onError: onFailed,
    onTimeout: onFailed,
    onFatal: onFailed,
});
