import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Home from './routes/home';

function Application() {
    return (
        <Switch>
            <Route path="/home">
                <Home />
            </Route>
            <Route path="*">
                <Redirect to="/home" />
            </Route>
        </Switch>
    );
}

Application.propTypes = {};

Application.defaultProps = {};

export default Application;
