import reduce from 'lodash/reduce';

function beforeRequest(context, requestContext) {
    context.dispatch('SET_REQUIREMENTS_STATUS', [null, true]);

    const sessionStore = context.getStore('Session');

    const authMethod = sessionStore.getAuthMethod;

    const data = reduce(
        requestContext.getData(),
        (acc, allAgreementId) => {
            acc[allAgreementId] = true;

            return acc;
        },
        {
            userType: authMethod,
        },
    );

    requestContext.setData(data);
}

function onSuccess(context) {
    context.service.getSession();

    context.dispatch('RESET_REQUIREMENTS');
}

function onFailed(context, { error }) {
    context.dispatch('SET_REQUIREMENTS_STATUS', [error, false]);
}

export default () => ({
    serviceName: 'resolveEula',
    config: {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: '/common/v2/requirements/eula',
    },
    beforeRequest,
    onSuccess,
    onFailed,
    onError: onFailed,
    onTimeout: onFailed,
    onFatal: onFailed,
});
