import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connectToStores } from 'fluxible-addons-react';
import Button from '@audacious/components/components/Button';
import './feedback-button.scss';

class FeedbackButton extends PureComponent {
    render() {
        const { feedbackUrl } = this.props;

        return (
            <Button
                className="feedback-btn"
                size="xs"
                color="secondary"
                dockSide="right"
                asAnchor
                variant="fill"
                href={feedbackUrl}
                target="_blank"
            >
                Feedback
            </Button>
        );
    }
}

FeedbackButton.propTypes = {
    feedbackUrl: PropTypes.string.isRequired,
};

FeedbackButton.defaultProps = {};

export default connectToStores(
    FeedbackButton,
    ['ApplicationContext'],
    context => {
        const applicationContext = context.getStore('ApplicationContext');

        return {
            feedbackUrl: applicationContext.getFeedbackUrl(),
        };
    },
);
