import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import applyFluxibleContext from '@audacious/web-common/fluxible/applyFluxibleContext';
import { connectToStores } from 'fluxible-addons-react';
import isEmpty from 'lodash/isEmpty';
import TextInput from '@audacious/components/components/TextInput';
import Button from '@audacious/components/components/Button';
import { Paragraph } from '@audacious/components/components/Typography';
import {
    PageTitle,
    PageContainer,
} from '@audacious/components/components/Page';
import { Row, Column } from '@audacious/components/components/Grid';

class PasswordForm extends PureComponent {
    constructor(props) {
        super(props);

        this.handleNewPasswordChange = this.handleNewPasswordChange.bind(this);
        this.handleConfirmPasswordChange = this.handleConfirmPasswordChange.bind(
            this,
        );
        this.handleSubmit = this.handleSubmit.bind(this);

        this.state = {
            newPassword: '',
            confirmPassword: '',
        };
    }

    componentDidMount() {
        const {
            fluxibleContext: {
                service: {
                    retrieveSecuritySettings,
                },
            },
        } = this.props;

        retrieveSecuritySettings();
    }

    handleNewPasswordChange(newPassword) {
        this.setState({ newPassword });
    }

    handleConfirmPasswordChange(confirmPassword) {
        this.setState({ confirmPassword });
    }

    handleSubmit() {
        const { onSubmit } = this.props;

        const { newPassword } = this.state;

        onSubmit(newPassword);
    }

    render() {
        const {
            status: { processing, failure },
            passwordGuidance,
        } = this.props;

        const { newPassword, confirmPassword } = this.state;

        const submitIsDisabled =
            !newPassword ||
            !confirmPassword ||
            newPassword !== confirmPassword ||
            processing;

        let issues = failure || [];

        if (newPassword && confirmPassword && newPassword !== confirmPassword) {
            issues = ['Passwords do not match', ...issues];
        }

        const messages = !isEmpty(issues) ? { invalid: issues } : null;
        // '{"invalid":["Message Number 1","Message Number 2"]}'

        return (
            <>
                <PageTitle
                    id="set-password-page-title"
                    pageName="Set Account Password"
                />
                <PageContainer asCard>
                    <Row gutter="16">
                        <Column
                            width={[null, null, '4']}
                            order={[null, null, '2']}
                        >
                            <Paragraph className="password-guidance" size="md">
                                {passwordGuidance}
                            </Paragraph>
                        </Column>
                        <Column width={[null, null, '8']}>
                            <Row gutter="16">
                                <Column>
                                    <TextInput
                                        id="new-password-input"
                                        placeholder="New Password"
                                        value={newPassword}
                                        onChange={this.handleNewPasswordChange}
                                        secure
                                        invalid={!isEmpty(issues)}
                                    />
                                </Column>
                                <Column>
                                    <TextInput
                                        id="confirm-password-input"
                                        placeholder="Confirm New Password"
                                        value={confirmPassword}
                                        onChange={
                                            this.handleConfirmPasswordChange
                                        }
                                        secure
                                        invalid={!isEmpty(issues)}
                                        messages={messages}
                                    />
                                </Column>
                                <Column>
                                    <Button
                                        id="create-password-submit-btn"
                                        className="submit-button"
                                        variant="fill"
                                        color="primary"
                                        disabled={submitIsDisabled}
                                        onClick={this.handleSubmit}
                                    >
                                        Submit
                                    </Button>
                                </Column>
                            </Row>
                        </Column>
                    </Row>
                </PageContainer>
            </>
        );
    }
}

PasswordForm.contextTypes = {
    service: PropTypes.objectOf(
        PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    ).isRequired,
};

PasswordForm.propTypes = {
    passwordGuidance: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
    status: PropTypes.shape({
        processing: PropTypes.bool.isRequired,
        failure: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
    fluxibleContext: PropTypes.shape({
		service: PropTypes.shape({
            retrieveSecuritySettings: PropTypes.func.isRequired,
		}),
	}).isRequired,
};

PasswordForm.defaultProps = {};

export default connectToStores(
    applyFluxibleContext(PasswordForm),
    ['Requirements', 'Password'],
    context => {
        const requirementsStore = context.getStore('Requirements');

        const passwordStore = context.getStore('Password');
        const passwordGuidance = passwordStore.getPasswordGuidance();

        return {
            status: requirementsStore.getRequirementsStatus(),
            passwordGuidance,
        };
    },
);
