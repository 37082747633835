import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connectToStores } from 'fluxible-addons-react';
import { Overline } from '@audacious/components/components/Typography';

class Footer extends PureComponent {
    constructor(...args) {
        super(...args);

        this.state = {};
    }

    render() {
        const { l10n } = this.props;

        return (
            <footer className="unauthenticated-footer" role="contentinfo">
                <Overline className="footer-copyright">
                    {l10n('client.text.footer.content.copyright')}
                </Overline>
                <div className="footer-logo">
                    <img
                        alt={l10n('client.images.footer.alt')}
                        src={l10n('client.images.footer.url')}
                    />
                </div>
            </footer>
        );
    }
}

Footer.propTypes = {
    l10n: PropTypes.func.isRequired,
};

Footer.defaultProps = {};

export default connectToStores(Footer, ['ApplicationContext'], context => ({
    l10n: context.getStore('ApplicationContext').l10n,
}));
