import brand from '../assets/images/brand.png';
import footer from '../assets/images/footer.jpg';

export default {
    brand: {
        url: brand,
        alt: 'Ai Web Client Framework: Brand',
    },
    footer: {
        url: footer,
        alt: 'Ai Web Client Framework: Footer',
    },
};
