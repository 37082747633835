import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connectToStores } from 'fluxible-addons-react';
import isNil from 'lodash/isNil';
import { AuthenticatedPage } from '@audacious/client';
// import { PageContainer } from '@audacious/components/components/Page';
import requirementRoutes from './routes';

function Requirements(props) {
    const { primaryRequirement } = props;

    return (
        <Switch>
            <Route exact path="/requirements/:id">
                {routeProps => {
                    const {
                        match: {
                            params: { id: requirementId },
                        },
                    } = routeProps;

                    if (primaryRequirement !== requirementId) {
                        // If we are not on a route for the primary requirement
                        // then we need to go there now.
                        return (
                            <Redirect
                                to={`/requirements/${primaryRequirement}`}
                            />
                        );
                    }

                    const RequirementPage = requirementRoutes[requirementId];

                    if (isNil(RequirementPage)) {
                        throw new Error(
                            `Unknown requirement id: ${requirementId}`,
                        );
                    }

                    return (
                        <AuthenticatedPage disableUserActions>
                            {/* <PageContainer asCard> */}
                            <RequirementPage />
                            {/* </PageContainer> */}
                        </AuthenticatedPage>
                    );
                }}
            </Route>
            <Route path="*">
                <Redirect to={`/requirements/${primaryRequirement}`} />
            </Route>
        </Switch>
    );
}

Requirements.propTypes = {
    primaryRequirement: PropTypes.string.isRequired,
};

Requirements.defaultProps = {};

export default connectToStores(Requirements, ['Session'], context => {
    const sessionStore = context.getStore('Session');

    return {
        primaryRequirement: sessionStore.getRequirements()[0],
    };
});
