/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import applyFluxibleContext from '@audacious/web-common/fluxible/applyFluxibleContext';
import { SubHeading , Paragraph } from '@audacious/components/components/Typography';
import Link from '@audacious/components/components/Link';
import Button from '@audacious/components/components/Button';
import { Row, Column } from '@audacious/components/components/Grid';
import TextInput from '@audacious/components/components/TextInput';

class LoginForm extends PureComponent {
    constructor(props) {
        super(props);

        this.handleSwitchToForgotPassword = this.handleSwitchToForgotPassword.bind(
            this,
        );
        this.handleSwitchToSSOLogin = this.handleSwitchToSSOLogin.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleUsernameChange = this.handleUsernameChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);

        this.state = {
            username: null,
            password: null,
        };
    }

    handleSubmit(event) {
        event.preventDefault();

        const {
            fluxibleContext: {
                service: {
                    createSession,
                }
            }
        } = this.props;

        const { username, password } = this.state;

        createSession({
            data: { username, password },
        });
    }

    handleSwitchToForgotPassword() {
        const { onChangeMode } = this.props;

        onChangeMode('forgot');
    }

    handleSwitchToSSOLogin() {
        const { onChangeMode } = this.props;

        onChangeMode('sso');
    }

    handleUsernameChange(username) {
        this.setState({
            username,
        });
    }

    handlePasswordChange(password) {
        this.setState({
            password,
        });
    }

    render() {
        const { invalid, disabled } = this.props;

        const { username, password } = this.state;

        const errorMessage = invalid ? (
            <Paragraph size="md" color="danger">
                Login failed. Contact your System Administrator for assistance.
            </Paragraph>
        ) : null;

        return (
            <form onSubmit={this.handleSubmit}>
                <Row gutter="16">
                    <Column width="content">
                        <SubHeading level="5">Login</SubHeading>
                    </Column>
                    <Column width="content" leftOffset="fill">
                        <Link
                            id="login-button-use-sso"
                            onClick={this.handleSwitchToSSOLogin}
                            asButton
                            size="xs"
                        >
                            Sign in with SSO
                        </Link>
                    </Column>
                </Row>
                <Row gutter="16">
                    <Column>{errorMessage}</Column>
                </Row>
                <Row gutter="8">
                    <Column>
                        <TextInput
                            id="login-input-username"
                            placeholder="Username"
                            required
                            onChange={this.handleUsernameChange}
                            value={username}
                            aria-label="Username"
                            disabled={disabled}
                            autoComplete="on"
                        />
                    </Column>
                    <Column>
                        <TextInput
                            id="login-input-password"
                            placeholder="Password"
                            required
                            onChange={this.handlePasswordChange}
                            value={password}
                            autoComplete="off"
                            aria-label="Password"
                            secure
                            disabled={disabled}
                        />
                    </Column>
                </Row>
                <Row gutter="16">
                    <Column>
                        <Link
                            id="login-button-forgot-password"
                            onClick={this.handleSwitchToForgotPassword}
                            asButton
                            disabled={disabled}
                            size="xs"
                        >
                            Forgot Password?
                        </Link>
                    </Column>
                </Row>
                <Row gutter="16">
                    <Column>
                        <Button
                            id="login-button-submit"
                            type="submit"
                            disabled={
                                isEmpty(username) ||
                                isEmpty(password) ||
                                disabled
                            }
                        >
                            Login
                        </Button>
                    </Column>
                </Row>
            </form>
        );
    }
}

LoginForm.propTypes = {
    invalid: PropTypes.bool,
    disabled: PropTypes.bool,
    onChangeMode: PropTypes.func.isRequired,
    fluxibleContext: PropTypes.shape({
		service: PropTypes.shape({
            createSession: PropTypes.func.isRequired,
		}),
	}).isRequired,
};

LoginForm.defaultProps = {
    disabled: false,
    invalid: false,
};

export default applyFluxibleContext(LoginForm);
