/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import applyFluxibleContext from '@audacious/web-common/fluxible/applyFluxibleContext';
import { Row, Column } from '@audacious/components/components/Grid';
import { SubHeading , Paragraph } from '@audacious/components/components/Typography';
import TextInput from '@audacious/components/components/TextInput';
import Button from '@audacious/components/components/Button';

class ForgotPasswordForm extends PureComponent {
    constructor(props) {
        super(props);

        this.handleCancel = this.handleCancel.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleUserNameChange = this.handleUserNameChange.bind(this);

        this.state = {
            username: null,
        };
    }

    handleSubmit(event) {
        event.preventDefault();

        const { username } = this.state;

        const { 
            fluxibleContext:{
                service: {
                    requestPassword
                }
            }
         } = this.props;

        requestPassword({
            data: { username },
        });
    }

    handleCancel() {
        const { onChangeMode } = this.props;

        onChangeMode('username');
    }

    handleUserNameChange(username) {
        this.setState({
            username,
        });
    }

    render() {
        const { disabled, invalid } = this.props;

        const { username } = this.state;

        const invalidMessage = invalid ? (
            <Column>
                <Paragraph size="md" color="danger">
                    The request to change password has failed.
                </Paragraph>
            </Column>
        ) : null;

        return (
            <form onSubmit={this.handleSubmit}>
                <Row gutter="16">
                    <Column>
                        <SubHeading level="5">Forgot Password</SubHeading>
                    </Column>
                    {invalidMessage}
                    <Column>
                        <TextInput
                            id="forgot-password-input-username"
                            type="username"
                            placeholder="Username"
                            required
                            onChange={this.handleUserNameChange}
                            value={username}
                            aria-label="Username"
                            disabled={disabled}
                        />
                    </Column>
                </Row>
                <Row gutter="16">
                    <Column width="content">
                        <Button
                            id="forgot-password-button-submit"
                            type="submit"
                            aria-label="Request Password"
                            disabled={isEmpty(username) || disabled}
                        >
                            Request Password
                        </Button>
                    </Column>
                    <Column width="fill" align="center">
                        <Button
                            id="forgot-password-button-cancel"
                            aria-label="Cancel"
                            onClick={this.handleCancel}
                            variant="opaque"
                            disabled={disabled}
                        >
                            Cancel
                        </Button>
                    </Column>
                </Row>
            </form>
        );
    }
}

ForgotPasswordForm.propTypes = {
    disabled: PropTypes.bool,
    invalid: PropTypes.bool,
    onChangeMode: PropTypes.func.isRequired,
    fluxibleContext: PropTypes.shape({
		service: PropTypes.shape({
			requestPassword: PropTypes.func
		}),
	}).isRequired,
};

ForgotPasswordForm.defaultProps = {
    disabled: false,
    invalid: false,
};

export default applyFluxibleContext(ForgotPasswordForm);
