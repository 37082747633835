import shuffle from 'lodash/shuffle';

function onSuccess(context, { response }) {
    context.dispatch('SET_SELECTED_QUESTIONS', [null, shuffle(response.data)]);
}

function onFailed(context, { error }) {
    context.dispatch('SET_SELECTED_QUESTIONS', [error, []]);
}

export default () => ({
    serviceName: 'retrieveSelectedQuestions',
    config: {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: '/common/v1/securityQuestions/retrieve',
    },
    onSuccess,
    onFailed,
    onError: onFailed,
    onTimeout: onFailed,
    onFatal: onFailed,
});
