import images from './images';
import login from './login';
import footer from './footer';

export default {
    images,
    text: {
        login,
        footer,
    },
};
