import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import applyFluxibleContext from '@audacious/web-common/fluxible/applyFluxibleContext';
import { connectToStores } from 'fluxible-addons-react';
import times from 'lodash/times';
import map from 'lodash/map';
import { Row, Column } from '@audacious/components/components/Grid';
import Data, { DataList, DataButton } from '@audacious/components/components/Data';
import {
    PageTitle,
    PageContainer,
} from '@audacious/components/components/Page';
import { Paragraph } from '@audacious/components/components/Typography';
import isNil from 'lodash/isNil';
import DataResponse from './data-response';

function buildResponses(numberOfRequiredQuestions) {
    return times(numberOfRequiredQuestions, index => ({
        index,
        questionId: null,
        questionText: null,
        answer: null,
    }));
}

class SetSecurityQuestions extends React.Component {
    constructor(props) {
        super(props);

        this.dataRef = createRef();

        this.handleExecuteStart = this.handleExecuteStart.bind(this);
        this.handleExecute = this.handleExecute.bind(this);
        this.handleQuestionChange = this.handleQuestionChange.bind(this);

        const { numberOfRequiredQuestions } = props;

        this.state = {
            responses: buildResponses(numberOfRequiredQuestions),
            usedQuestions: {},
        };
    }

    componentDidMount() {
        const { 
            fluxibleContext: {
                service: {
                    retrieveAvailableQuestions,
                }
            }
        } = this.props;

        retrieveAvailableQuestions();
    }

    handleQuestionChange(oldQuestionId, newQuestionId) {
        const { usedQuestions } = this.state;

        if (!isNil(oldQuestionId)) {
            usedQuestions[oldQuestionId] = false;
        }
        usedQuestions[newQuestionId] = true;

        this.setState({
            usedQuestions: {
                ...usedQuestions,
            },
        });
    }

    // eslint-disable-next-line class-methods-use-this
    handleExecuteStart(_, currentResults) {
        return isNil(currentResults);
    }

    handleExecute(value) {
        const {
            availableQuestions,
            fluxibleContext: {
                service: {
                    resolveSetSecurityQuestions
                },
            },
         } = this.props;

        const responseData = map(value, response => {
            const { questionId, questionText, answer } = response;

            const question = availableQuestions.questions.getById(questionId);

            const text = question.isCustom
                ? questionText
                : question.question;

            return {
                questionId,
                question: text,
                answer,
            };
        });

        resolveSetSecurityQuestions({
            data: responseData,
        });
    }

    render() {
        const {
            availableQuestions: { isLoaded, questions },
        } = this.props;

        const { responses, usedQuestions } = this.state;

        let content = null;

        if (isLoaded) {
            content = (
                <Data
                    ref={this.dataRef}
                    baseValue={responses}
                    validateOnBlur
                    validateOnExecute
                    showResultsOnTouch
                    showResultsOnExecute
                    onExecuteStart={this.handleExecuteStart}
                    onExecute={this.handleExecute}
                >
                    <Row>
                        <Column>
                            <Paragraph size="md">
                                Please select your security questions and
                                answers
                            </Paragraph>
                        </Column>
                    </Row>
                    <DataList
                        InnerComponent={Row}
                        InnerProps={{
                            gutter: '16',
                        }}
                        InnerItemComponent={Column}
                    >
                        <DataResponse
                            availableQuestions={questions}
                            usedQuestions={usedQuestions}
                            onQuestionChange={this.handleQuestionChange}
                        />
                    </DataList>
                    <Row gutter="16">
                        <Column>
                            <DataButton
                                id="set-security-questions-btn"
                                variant="fill"
                                color="primary"
                                onClick={() => {
                                    this.dataRef.current.execute();
                                }}
                            >
                                Save
                            </DataButton>
                        </Column>
                    </Row>
                </Data>
            );
        }

        return (
            <>
                <PageTitle
                    id="set-password-page-title"
                    pageName="Security Questions"
                />
                <PageContainer
                    className="select-questions-page"
                    asCard={isLoaded}
                    isLoading={!isLoaded}
                >
                    {content}
                </PageContainer>
            </>
        );
    }
}

SetSecurityQuestions.propTypes = {
    availableQuestions: PropTypes.shape({
        // eslint-disable-next-line react/forbid-prop-types
        questions: PropTypes.object,
        // eslint-disable-next-line react/forbid-prop-types
        failure: PropTypes.object,
        isLoaded: PropTypes.bool.isRequired,
    }).isRequired,
    status: PropTypes.shape({
        processing: PropTypes.bool.isRequired,
        // eslint-disable-next-line react/forbid-prop-types
        failure: PropTypes.object,
    }).isRequired,
    numberOfRequiredQuestions: PropTypes.number.isRequired,
    fluxibleContext: PropTypes.shape({
		service: PropTypes.shape({
			resolveSetSecurityQuestions: PropTypes.func.isRequired,
            retrieveAvailableQuestions: PropTypes.func.isRequired,
		}),
	}).isRequired,
};

SetSecurityQuestions.defaultProps = {};

export default connectToStores(
    applyFluxibleContext(SetSecurityQuestions),
    ['Requirements', 'ApplicationContext'],
    context => {
        const requirementsStore = context.getStore('Requirements');

        const availableQuestions = requirementsStore.getAvailableQuestions();

        const status = requirementsStore.getRequirementsStatus();

        const applicationContextStore = context.getStore('ApplicationContext');
        const numberOfRequiredQuestions = applicationContextStore.config(
            'security.numberOfRequiredQuestions',
        );

        return {
            availableQuestions,
            status,
            numberOfRequiredQuestions,
        };
    },
);
