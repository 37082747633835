import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import applyFluxibleContext from '@audacious/web-common/fluxible/applyFluxibleContext';
import { connectToStores } from 'fluxible-addons-react';
import LoginForm from './login-form';
import SSOForm from './sso-form';
import ForgotPasswordForm from './forgot-password-form';
import {
    clearSessionFailure,
    setLoginMode,
} from '../../../actions/login-actions';

class Login extends PureComponent {
    constructor(props) {
        super(props);

        this.handleChangeMode = this.handleChangeMode.bind(this);
    }

    handleChangeMode(mode) {
        const {
            fluxibleContext: {
                executeAction,
            }
        } = this.props;

        executeAction(clearSessionFailure);

        executeAction(setLoginMode, mode);
    }

    render() {
        const {
            disabledLogin,
            invalidLogin,
            disabledForgotPassword,
            invalidForgotPassword,
            mode,
        } = this.props;

        if (mode === 'forgot') {
            return (
                <ForgotPasswordForm
                    disabled={disabledForgotPassword}
                    invalid={invalidForgotPassword}
                    onChangeMode={this.handleChangeMode}
                />
            );
        }

        if (mode === 'username') {
            return (
                <LoginForm
                    disabled={disabledLogin}
                    invalid={invalidLogin}
                    onChangeMode={this.handleChangeMode}
                />
            );
        }

        return (
            <SSOForm
                disabled={disabledLogin}
                invalid={invalidLogin}
                onChangeMode={this.handleChangeMode}
            />
        );
    }
}

Login.propTypes = {
    disabledLogin: PropTypes.bool,
    disabledForgotPassword: PropTypes.bool,
    invalidLogin: PropTypes.bool,
    invalidForgotPassword: PropTypes.bool,
    mode: PropTypes.string.isRequired,
    fluxibleContext: PropTypes.shape({
        executeAction: PropTypes.func.isRequired,
	}).isRequired,
};

Login.defaultProps = {
    disabledLogin: false,
    disabledForgotPassword: false,
    invalidLogin: false,
    invalidForgotPassword: false,
};

export default connectToStores(applyFluxibleContext(Login), ['LoginStore'], context => {
    const loginStore = context.getStore('LoginStore');

    return {
        mode: loginStore.getMode(),
    };
});
