import client from '../../localization';

export default {
    version: '0.0.1',
    schema: '0.0.1',
    locale: 'en-US',
    configuration: {
        dashboard: {
            password: {
                change: {
                    error:
                        'Something went wrong. Please try again or contact your administrator.',
                    success: 'Your password has been updated successfully!',
                },
                emailSent:
                    'If this account is active you will receive an email to reset your password.',
            },
            questions: {
                incorrect:
                    'The answer you have entered is incorrect. Please try again.',
                maxAttempts:
                    'Max attempts reached to enter your security question. Please contact your administrator.',
                headingText: 'Please answer the following Security Question:',
                error:
                    'Something went wrong. Please try again or contact your administrator.',
            },
        },
        client,
    },
};
