import split from 'lodash/split';

function beforeRequest(context) {
    context.dispatch('SET_REQUIREMENTS_STATUS', [null, true]);
}

function onSuccess(context) {
    context.service.getSession();

    const { l10n } = context.getStore('ApplicationContext');

    context.dispatch(
        'SET_PASSWORD_MESSAGE',
        l10n('dashboard.password.change.success'),
    );

    context.dispatch('RESET_REQUIREMENTS');
}

function onFailed(context, { response }) {
    const { l10n } = context.getStore('ApplicationContext');

    let issues = [];

    if (
        response.status &&
        response.status >= 400 &&
        response.status < 500 &&
        response.data
    ) {
        const responseParts = split(response.data, '"');

        if (responseParts && responseParts.length > 0) {
            issues = split(responseParts[1], '|');
        } else {
            issues = [l10n('dashboard.password.change.error')];
        }
    } else {
        issues = [l10n('dashboard.password.change.error')];
    }

    context.dispatch('SET_REQUIREMENTS_STATUS', [issues, false]);
}

export default () => ({
    serviceName: 'resolveCreatePassword',
    config: {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: '/common/v2/requirements/create-password',
    },
    beforeRequest,
    onSuccess,
    onFailed,
    onError: onFailed,
    onTimeout: onFailed,
    onFatal: onFailed,
});
