import merge from 'lodash/merge';

const DEFAULT_MOCK_SERVICES = {};

/* These are the default mock service configurations.
 * enabled - When set to true will enable ALL mocks.
 * include - a list of service names which will ALWAYS be mocked, even if enabled is false.
 * exclude - a list of service names which will NEVER be mocked, even is enabled is true.
 * immediate - When set to false, ALL service request will prompt the user one how to proceed. Meant for DEBUG purposes. This does not require mocks to be enabled.
 */
export default ({
    enabled = false,
    include = [],
    exclude = [],
    immediate = true,
    services = {},
}) => ({
    enabled,
    include,
    exclude,
    immediate,
    services: merge(DEFAULT_MOCK_SERVICES, services),
});
