import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connectToStores } from 'fluxible-addons-react';
import isNil from 'lodash/isNil';
import FeedbackButton from '../common/feedback-button';
import LoginContent from './login-content';
import UnauthenticatedPage from '../common/unauthenticated-page';
import './login-page.scss';

class LoginPage extends PureComponent {
    render() {
        const {
            loading,
            invalidLogin,
            invalidForgotPassword,
            passwordMessage,
        } = this.props;

        return (
            <>
                <UnauthenticatedPage>
                    <LoginContent
                        loading={loading}
                        passwordMessage={passwordMessage}
                        invalidLogin={invalidLogin}
                        invalidForgotPassword={invalidForgotPassword}
                    />
                </UnauthenticatedPage>
                <FeedbackButton />
            </>
        );
    }
}

LoginPage.propTypes = {
    loading: PropTypes.bool,
    invalidLogin: PropTypes.bool,
    invalidForgotPassword: PropTypes.bool,
    passwordMessage: PropTypes.string,
};

LoginPage.defaultProps = {
    loading: false,
    invalidLogin: false,
    invalidForgotPassword: false,
    passwordMessage: null,
};

export default connectToStores(LoginPage, ['Session', 'Password'], context => {
    const passwordStore = context.getStore('Password');
    const sessionStore = context.getStore('Session');

    const { failure: passwordFailure, processing } = passwordStore.getStatus();

    const running = sessionStore.getIsLoading() || processing;

    return {
        loading: running,
        successForgotPassword: passwordStore.getMessage(),
        invalidLogin: !isNil(sessionStore.getFailure()),
        invalidForgotPassword: !isNil(passwordFailure),
        passwordMessage: passwordStore.getMessage(),
    };
});
