import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import isNil from 'lodash/isNil';
import { DataSelectProperty, DataTextProperty } from '@audacious/components/components/Data';
import { Row, Column } from '@audacious/components/components/Grid';

function Response({
    value,
    availableQuestions,
    usedQuestions,
    onQuestionChange,
}) {
    const {
        questionId: responseQuestionId,
        index: questionNumber,
    } = value;

        const questionOptions = availableQuestions.reduce((acc, question) => {
            const { id: questionId, question: questionText } = question;

            if (!usedQuestions[questionId] || questionId === responseQuestionId) {
                acc.push({
                    label: questionText,
                    value: {
                        id: questionId,
                    },
                });
            }

            return acc;
        }, []);

    const targetQuestion = useMemo(() => !isNil(responseQuestionId)
        ? availableQuestions.getById(responseQuestionId)
        : {}, [responseQuestionId, availableQuestions]);

    return (
        <Row gutter="8">
            <Column
                width={[null, null, '6']}
                rightOffset={targetQuestion.isCustom ? [null, null, '6'] : null}
            >
                <DataSelectProperty
                    id={`response-${questionNumber}-question`}
                    path="questionId"
                    label={`Select Question ${questionNumber + 1}`}
                    options={questionOptions}
                    onChange={(questionId) => { 
                        onQuestionChange(responseQuestionId, questionId);
                    }}
                    keyPath="value.id"
                    optionTextPath="label"
                    required={[true, 'Please select a question']}
                />
            </Column>
            {targetQuestion.isCustom
                ? (
                    <Column width={[null, null, '6']}>
                        <DataTextProperty
                            id={`response-${questionNumber}-text`}
                            label="Question"
                            className="custom-question"
                            path="questionText"
                            required={[true, 'Please write a question.']}
                        />
                    </Column> 
                )
                : null
            }
            <Column width={[null, null, '6']}>
                <DataTextProperty
                    id={`response-${questionNumber}-answer`}
                    path="answer"
                    label="Answer"
                    className="answer"
                    minLength={[3, 'An answer must be more than 2 characters']}
                    required={[true, 'Please write an answer.']}
                />
            </Column>
        </Row>
    );
}

Response.propTypes = {
    value: PropTypes.shape({
        questionId: PropTypes.string,
        index: PropTypes.number,
    }).isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    availableQuestions: PropTypes.object.isRequired,
    usedQuestions: PropTypes.objectOf(PropTypes.bool).isRequired,
    onQuestionChange: PropTypes.func.isRequired,
};

export default memo(Response);
